@use '@angular/material' as mat;
@include mat.core();

body {
  height: 100vh;
  margin: 0;
  live-product-live-map {
    display: block;
    height: 100%;
  }
}

//#region Initialize Material Theme w/ Duke Colors
@import './app/styles/fonts.scss';
@import './app/styles/material-palette'; //#TODO: file must be an exact copy of the one in your host package so that the colors are in sync w/ the rest of the application

$primary: mat.define-palette($duke-primary, 500);
$accent: mat.define-palette($duke-accent, A200, A100, A400);
$warn: mat.define-palette($duke-warn);
$custom-typography: mat.define-typography-config(
  $font-family: font-family('NewsGothicBT-Roman'),
);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: $custom-typography,
    density: 0,
  )
);
@include mat.all-component-themes($theme);
//#endregion

//#region Overrides
//#region Material Overrides
mat-tab-group {
  div.mdc-tab {
    background-color: mat.get-color-from-palette($primary, 500);
    &:hover {
      background-color: mat.get-color-from-palette($primary, A700);
    }
    &--active {
      background-color: mat.get-color-from-palette($primary, '500-contrast');
      .mdc-tab__text-label {
        color: mat.get-color-from-palette($primary, 500) !important;
      }
      .mdc-tab-indicator__content--underline {
        border-color: mat.get-color-from-palette($primary, A400) !important;
      }
      &:hover {
        .mdc-tab__text-label {
          color: mat.get-color-from-palette(
            $primary,
            '500-contrast'
          ) !important;
        }
      }
    }
  }
}

mat-tab-body {
  padding: 10px;
}
//#endregion Material Overrides
//#endregion Overrides
